import { setModal } from "@redux/shared/slices/modal";
import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import styles from "./footer.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { convertToBreakpointVariables } from "@page-builder/utils";
import { injectDoNotSellInFooter } from "@lib/sharedUtils";

const yearNow = new Date();

interface Link {
    title: string;
    href?: string;
    onClick?: () => void;
}

export default function Footer({
    textColor,
    bgColor,
    addCcpaPrivacyNotice,
}: {
    textColor?: string;
    bgColor?: string;
    addCcpaPrivacyNotice?: boolean;
}): ReactElement {
    const dispatch = useDispatch();

    const { domain, pageType } = useDomainContext();
    const { visitDetails, routeConfig } = useDomainContext();
    let contactUsLink = null;
    if (visitDetails?.trafficSourceNetwork?.toLowerCase() === "outbrain") {
        contactUsLink = (
            <a className="cursor-pointer" href="/contact-us">
                Contact Us
            </a>
        );
    }
    const footerLinks: Link[] = [
        {
            title: "Terms & Conditions",
            onClick: () =>
                dispatch(setModal({ active: true, content: "terms" })),
        },
        {
            title: "Privacy Policy",
            onClick: () =>
                dispatch(setModal({ active: true, content: "privacy" })),
        },
        {
            title: "Partner with Us",
            href: "/partner-with-us",
        },
    ];
    if (addCcpaPrivacyNotice) {
        footerLinks.push({
            title: "CCPA Privacy Notice",
            onClick: () =>
                dispatch(
                    setModal({
                        active: true,
                        content: "ccpaPrivacyNotice",
                    }),
                ),
        });
    }
    injectDoNotSellInFooter({
        footerArray: footerLinks,
        pageType: pageType,
        trafficSource: visitDetails?.trafficSource,
    });

    const variables = routeConfig?.config?.footer?.variables;

    return (
        <footer
            style={{
                color: textColor,
                backgroundColor: bgColor,
                ...(variables ? convertToBreakpointVariables(variables) : {}),
            }}
            className={`${styles["footer"]}  text-center lg:px-6 lg:py-0`}
        >
            <div className="max-w-7xl mx-auto">
                <div
                    className={`${
                        styles["inner"]
                    } lg:flex lg:justify-between lg:items-center flex-row-reverse ${
                        !bgColor ? "border-t" : ""
                    } lg:mx-auto px-4 lg:px-0 py-6 pb-36 lg:py-4`}
                >
                    <div className={`footer-menu ${styles["footer-menu"]}`}>
                        <ul className="flex justify-center flex-col md:flex-row mb-3 lg:mb-0  children:last:border-r-0">
                            {footerLinks.map((item, index) => (
                                <li
                                    key={index}
                                    className={`px-2 font-medium lg:font-light text-sm lg:leading-4 ${styles["menu-item"]} `}
                                >
                                    <a
                                        className=" cursor-pointer"
                                        onClick={item.onClick}
                                        href={item.href}
                                    >
                                        <span>{item.title}</span>
                                    </a>
                                </li>
                            ))}
                            {contactUsLink && (
                                <li
                                    className={`px-2 font-medium lg:font-light text-sm lg:leading-4 ${styles["menu-item"]} `}
                                >
                                    <span>{contactUsLink}</span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="font-light text-sm">
                        <span>
                            <span>©</span> <span>{yearNow.getFullYear()}</span>{" "}
                            <a href={`https://${domain.name}`}>
                                {domain.niceName}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
}
